window.loginState = false;

const {postCors} = require("../my_modules/3e8-ajaxhelpers");
const iframe = document.createElement('iframe');

let forcelogin = document.currentScript.dataset.forcelogin === "false" ? false : !!document.currentScript.dataset.forcelogin;
let BASEURL = document.currentScript.getAttribute("src").split("/").slice(0,3).join("/");
console.log("forcelogin1", forcelogin);

window.addEventListener("load", function() {
  iframe.classList.add("authframe");
  //iframe.style.display = "none";
  iframe.src = BASEURL+"/auth";
  (document.querySelector(".authframeholder") || document.body).appendChild(iframe);
  window.addEventListener ("message", e => {
    if(!["localhost", "3e8.ch", "loooping.ch", "gymburgdorf.ch"].some(o=>e.origin.includes(o))) return e.origin.includes("google") || console.warn("not authorized! " + e.origin);
    if(e.data.action && e.data.action === "forceLogin") {
      console.log("force Login after iframe message...");
      location.href = BASEURL + "/login#"+encodeURI(location.href);
      return;
    }
    if(e.data.state === undefined) return;
    window.loginState = localStorage.uid = e.data.state;
    if(e.data.state === false && forcelogin) {
      console.log("forcelogin2", forcelogin, encodeURI(location.href));
      localStorage.useSoftLogin = "false";
      location = BASEURL+"/login?soft=false#" + encodeURI(location.href);
    }
    document.dispatchEvent(new CustomEvent("loginStateChange", {detail: {loginState: e.data.state}}));
  });
});

async function getUserData() {
  return new Promise((resolve, reject)=> {
    document.addEventListener("loginStateChange", e => {
      if(e.detail.loginState) {
        resolve(fetchUserData(e.detail.loginState));
      }
      else reject("notOnline");
    }, {once: true});
  });
}

async function fetchUserData(uid = localStorage.uid) {
  return await postCors(BASEURL+"/getUserFromUid", {/*which: "basic",*/ uid: uid});
}

async function getUserDetails() {
  return new Promise((resolve, reject)=> {
    let waitForResponse = e => {
      if(e.data.type==="response" && e.data.action==="userDetails") {
        resolve(e.data.currentUserDetails);
        window.removeEventListener("message", waitForResponse);
      }
    };
    window.addEventListener("message", waitForResponse);
    iframe.contentWindow && iframe.contentWindow.postMessage({type: "request", action: "getUserDetails"}, "*");
  });
}

function forceLogin() {
  console.log("loginhandler force login...");
  iframe.contentWindow && iframe.contentWindow.postMessage({type: "task", action: "forceLogin"}, "*");
}

function OAuthSignOut() {
  iframe.contentWindow && iframe.contentWindow.postMessage({type: "task", action: "OAuthSignOut"}, "*");
}

window.getUserDetails = getUserDetails;
window.getUserData = getUserData;
//window.fetchUserData = fetchUserData;
window.forceLogin = forceLogin;
window.OAuthSignOut = OAuthSignOut;

// async function saveItem(item, value, uid=localStorage.uid) {
//   return await postCors("https://auth.3e8.ch/saveUserItem", {uid, item, value});
// }
//
// async function loadItem(item, uid = localStorage.uid) {
//   return await postCors("https://auth.3e8.ch/loadUserItem", {uid, item});
// }
//
// async function loadTemplate(item) {
//   return await postCors("https://auth.3e8.ch/loadTemplate", {item});
// }
//
//export {getUserData, saveItem, loadItem, loadTemplate};