/**
 *
 * @param url
 * @param data
 * @returns {Promise}
 */
function post(url, data) {
  return window.fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(checkStatus)
  .then(extractContent)
}

/**
 *
 * @param url
 * @param data
 * @returns {Promise}
 */
function postCors(url, data) {
  return window.fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, application/x-www-form-urlencoded, text/plain, text/json, */*',
      'Content-Type': 'application/x-www-form-urlencoded' // instead of application/json because of cors
    },
    body: JSON.stringify(data)
  })
  .then(checkStatus)
  .then(extractContent)
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    console.log(response);
    return Promise.reject(new Error("invalid status text: " + response.statusText))
  }
}

function extractContent(r) {
  var ct = r.headers.get("content-type");
  if(~ct.indexOf("json") || ~ct.indexOf("x-www-form-urlencoded")) return r.json();
  if(~ct.indexOf("text")) return r.text();
  return r;
}

export {post, postCors};
